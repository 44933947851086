let allToggles = document.querySelectorAll('.read-more');

for (let i = 0; i < allToggles.length; i++) {
  allToggles[i].setAttribute('data-id', i);
  allToggles[i].parentElement.setAttribute('data-toggled', 0);
  allToggles[i].onclick = function() {
    // If text is shown less, then show complete
    if (this.getAttribute('data-more') == 0) {
      this.innerHTML = 'weniger lesen';
      this.setAttribute('data-more', 1);
      this.previousElementSibling.style.display = 'block';
      this.previousElementSibling.previousElementSibling.style.display = 'none';
    }
    // If text is shown complete, then show less
    else if (this.getAttribute('data-more') == 1) {
      this.setAttribute('data-more', 0);
      this.innerHTML = 'mehr lesen';
      this.previousElementSibling.style.display = 'none';
      this.previousElementSibling.previousElementSibling.style.display =
        'block';
    }
  };
}

// Cookie consent

const enableCookiesButton = document.querySelector('#enableCookies');
const rejectCookiesButton = document.querySelector('#rejectCookies');

const gaID = 'UA-154790329-1';
const injectedAnalyticsScript = `
var gaProperty = "${gaID}";
var disableStr = "ga-disable-" + gaProperty;
if (document.cookie.indexOf(disableStr + "=true") > -1) {
    window[disableStr] = true;
}
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', '${gaID}', 'auto');
ga('set', 'anonymizeIp', true);
ga('send', 'pageview');
function gaOptout() {
    document.cookie =
    disableStr + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/";
    window[disableStr] = true;
    alert ('Das Tracking durch Google Analytics wurde deaktiviert');
}
`;

setCookiesIfEnabled();
setCookieConsentVisibility();

if (enableCookiesButton) {
  enableCookiesButton.onclick = () => {
    document.cookie =
      'areCookiesEnabled=true;path=/;expires=Fri, 31 Dec 2022 23:59:59 GMT';
    isCookieConsentVisible = false;
    setCookiesIfEnabled();
    setCookieConsentVisibility();
  };
}
if (enableCookiesButton) {
  rejectCookiesButton.onclick = () => {
    localStorage.setItem('areCookiesEnabled', false);
    setCookieConsentVisibility();
  };
}

function getCookie(name) {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookiesIfEnabled() {
  // Insert script tag if cookies are enabled
  if (getCookie('areCookiesEnabled')) {
    let script = document.createElement('script');
    script.async = true;
    script.insertAdjacentHTML('afterbegin', injectedAnalyticsScript);
    document.head.appendChild(script);
  }
}

function setCookieConsentVisibility() {
  const cookieConsent = document.querySelector('#cookieConsent');
  if (
    localStorage.getItem('areCookiesEnabled') ||
    getCookie('areCookiesEnabled')
  ) {
    cookieConsent.parentNode.removeChild(cookieConsent);
  }
}
